import React, { useEffect } from 'react';
import { Modal } from 'antd';
import useUpload from './useUploadCheckStudentStatus';

const ImportModal = ({ isVisible, setIsVisible, onClose, onUniversityUpdate }) => {
  const onSuccess = (data) => {
    console.log('onSuccess');
    try {
      let headerRow = ['citizen_id', 'title', 'first_name', 'last_name', 'code', 'message', 'is_registered'];

      const studentRows = data.map((rowData) => {
        return headerRow.map((header) => rowData[header]);
      });

      const rows = [
        headerRow,
        ...studentRows,
        // programRow.concat(scoreRow),
      ];

      let csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'ผลการตรวจสอบข้อมูลนักเรียน.csv');
      document.body.appendChild(link); // Required for FF
      link.click();
      setIsVisible(false);
    } catch (err) {
      console.log(err);
    }
  };

  const {
    title,
    filePath,
    currentUniversity,

    uploadRef,
    uploadCount,
    selectedFile,
    setSelectedFile,

    onUpload,
    uploading,
    uploadProgress,

    // importErrors,
  } = useUpload(onSuccess);

  // useEffect(() => onErrorsChange(importErrors), [importErrors]);

  return (
    <Modal
      visible={isVisible}
      onCancel={() => {
        setIsVisible(false);
      }}
      width={720}
      footer={null}
    >
      <h2>{title}</h2>
      <br />

      <p>เลือกไฟล์ที่ต้องการตรวจสอบข้อมูลสถานะนักเรียน แล้วคลิกปุ่มตรวจสอบข้อมูล</p>
      <p>
        สามารถดูตัวอย่างไฟล์ได้{' '}
        <a href="https://my-tcas.s3.ap-southeast-1.amazonaws.com/examples/checkstatus-files.csv" target="_blank">
          ที่นี่
        </a>
      </p>

      <div className="sec-form">
        <div className="col-75">
          <input type="file" accept=".csv" ref={uploadRef} onChange={(e) => setSelectedFile(e.target.files[0])} />
          <div className={uploadProgress ? 't-progress active-animation' : 't-progress'}>
            <b />
          </div>
        </div>
        <div className="col-25">
          <a className="btn-main" onClick={() => onUpload(() => onCancel())}>
            {uploading ? (
              <span>กำลังนำเข้าไฟล์ตรวจสอบข้อมูล</span>
            ) : (
              <span>
                <i className="i-upload" /> ตรวจสอบข้อมูล
              </span>
            )}
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default ImportModal;
