import React from 'react';
import _ from 'lodash';

const map = {
  1: {
    title: 'ข้อมูลการรับสมัคร',
    exampleFilePath: 'https://my-tcas.s3.ap-southeast-1.amazonaws.com/examples/applicant.csv',
    uploadURL: '/enrollment-offers/rounds/:round/import/enrollments',
  },
  2: {
    title: 'ข้อมูลผลการรับสมัคร',
    exampleFilePath: 'https://my-tcas.s3.ap-southeast-1.amazonaws.com/examples/applicant-result.csv',
    uploadURL: '/enrollment-offers/rounds/:round/import/results',
  },

  3: {
    title: 'ข้อมูลผลการสัมภาษณ์',
    exampleFilePath: 'https://my-tcas.s3.ap-southeast-1.amazonaws.com/examples/interview-result.csv',
    uploadURL: '/enrollment-offers/rounds/3/import/interviews',
  },
};

const getFileType = (university, index) => {
  switch (index) {
    case 1:
      return 'latest_enrollment_file';
    case 2:
      return 'latest_enrollment_result_file';
    case 3:
      return 'latest_enrollment_interview_file';

    default:
      return '';
  }
};

const getUpdatedAtField = (university, index) => {
  switch (index) {
    case 1:
      return 'updated_enrollment_at';
    case 2:
      return 'updated_enrollment_result_at';
    case 3:
      return 'updated_enrollment_interview_at';

    default:
      return '';
  }
};

const getCountField = (index) => {
  switch (index) {
    case 1:
      return 'enrollment_count';
    case 2:
      return 'enrollment_count';
    case 3:
      return 'enrollment_count';
    default:
      return '';
  }
};

const useImportInfo = (round, university, index = 1, config = { type: 'import' }) => {
  const fileType = getFileType(university, index);
  const updatedAtField = getUpdatedAtField(university, index);
  const countField = getCountField(index);

  const roundField = `round_${round}`;

  const uploadURL = map[index].uploadURL.replace(':round', round);
  const roundData = university[roundField] || {};

  const uploadable = index === 2 ? Boolean(roundData.latest_enrollment_file) : true;

  console.log(index, round, fileType, roundData[fileType]);

  return {
    ...map[index],
    uploadURL,
    fileType,
    updatedAtField,
    filePath: fileType ? roundData[fileType] : null,
    studentInfosFilePath: index === 1 ? roundData.latest_students_file : null,
    studentNotTestSetFilePath: index === 1 ? roundData.latest_students_not_testset_file : null,
    updatedAt: updatedAtField ? roundData[updatedAtField] : null,
    uploadable,
    roundData,
    count: roundData.enrollment_count,
    passCount: roundData.enrollment_pass_count,
    failCount: roundData.enrollment_fail_count,
  };
};

export default useImportInfo;
