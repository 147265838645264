import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ROUNDS from 'constants/rounds';
import { Link } from 'react-router-dom';

import moment from 'moment';
import cn from 'classnames';
import { find, get } from 'lodash';

// Components
import ImportModal from './ImportModal';
import VideoModal from 'components/VideoModal';

// Hooks
import useMyUniversity from 'hooks/useMyUniversity';
import useImportInfo from './useImportInfo';

import numeral from 'numeral';
const download = (url) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = url.split('/').pop();
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const ImportEnrollment = () => {
  const history = useHistory();
  const { loading, university, setUniversity, fetchUniversityInfo } = useMyUniversity();
  const [currentIndex, setCurrentIndex] = useState();
  const [errorTitle, setErrorTitle] = useState();
  const [importErrors, setImportErrors] = useState([]);
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);

  const adminConfig = useSelector((state) => state.configs || {});
  const [selectedRound, setSelectedRound] = useState(adminConfig.current_round);

  if (!university) return null;

  // if (adminConfig.is_closed_for_import) {
  //   history.push('/programs/update');
  // }
  return (
    <div>
      <h1>นำข้อมูลการรับสมัครเข้าระบบ</h1>

      <div className="sec-intro">
        <div className="info">
          <ol>
            <li>
              ให้นำข้อมูลไฟล์การรับสมัครที่มีรายชื่อและรหัสผู้สมัครเข้าระบบ
              <br />
              หลังจากนั้นระบบจะสร้างไฟล์ข้อมูลนักเรียนพร้อมคะแนนให้ (ไอคอนสีแดง)
            </li>
            <li>
              เมื่อสถาบันคัดเลือกรายชื่อเสร็จแล้วให้นำไฟล์ข้อมูลผลการรับสมัครเข้าระบบ
              <br />
              หลังจากนั้นระบบจะนำขึ้นเว็บไซต์และแจ้งให้ผู้สมัครมายืนยันสิทธิต่อไป
            </li>
          </ol>
        </div>
      </div>

      <div className="sec-announce">การนำข้อมูลเข้าต้องเรียงตามลำดับ 1-2 เท่านั้น</div>

      {Boolean(importErrors.length) && (
        <div className="sec-error">
          <h2>{errorTitle}</h2>
          <p>พบข้อผิดพลาดในไฟล์ CSV ดังนี้ ทั้งหมด {importErrors.length} แถว</p>
          <ul>
            {importErrors.map(({ index, message }, _idx) => (
              <li key={_idx}>
                แถวที่ <b className="index">{index}</b> <b className="msg">{message.subject}</b> {message.correction}
              </li>
            ))}
          </ul>
        </div>
      )}

      {adminConfig.isopen_for_round_3_enrollment_offer_interview &&
        [''].map(() => {
          const round = find(ROUNDS, { value: `3_2568` });
          return (
            <>
              <h2>รอบ {round.label}</h2>

              <ul className="sec-import -enrollments">
                {[''].map((_, index) => {
                  const {
                    title,
                    exampleFilePath,
                    filePath,
                    updatedAt,
                    uploadable,
                    count,

                    roundData,
                    studentInfosFilePath,
                  } = useImportInfo('3', university, 3);

                  return (
                    <li key={index} className={`round-${index + 1}`}>
                      <h2>
                        <b className="num">{index + 1}</b>
                        <b>
                          {title}
                          {roundData.enrollment_interview_pass_count ? (
                            <i>
                              {`ผ่าน ${numeral(roundData.enrollment_interview_pass_count).format('0,0')} รายการ`}
                              <br />
                              {`ไม่ผ่าน ${numeral(roundData.enrollment_interview_fail_count).format('0,0')} รายการ`}
                              <br />
                              {`รวม ${numeral(
                                roundData.enrollment_interview_pass_count + roundData.enrollment_interview_fail_count
                              ).format('0,0')} รายการ`}
                            </i>
                          ) : (
                            ''
                          )}
                        </b>
                      </h2>
                      <a className="example" href={exampleFilePath}>
                        ดาวน์โหลดไฟล์ตัวอย่าง
                      </a>
                      {filePath ? (
                        <div className="file">
                          <a href={filePath}>
                            <i className="i-course"></i>
                            <b>{filePath.split('/').pop()}</b>
                            <small>{moment(updatedAt).add(543, 'years').format('D MMM YY HH:mm น.')} </small>
                          </a>
                          {studentInfosFilePath && isDisplayDownloadStudents && (
                            <a className="file-students" href={studentInfosFilePath}>
                              <i className="i-course"></i>
                              <b>{studentInfosFilePath.split('/').pop()}</b>
                              <small>{moment(updatedAt).add(543, 'years').format('D MMM YY HH:mm น.')} </small>
                            </a>
                          )}
                        </div>
                      ) : (
                        <div className="file -none">
                          <a href="#">
                            <i className="i-course"></i>
                            <b>ยังไม่มีข้อมูล</b>
                          </a>
                        </div>
                      )}

                      <div className="action">
                        <a
                          className={cn('btn-main', { 'btn-disabled': !uploadable })}
                          style={uploadable ? null : { cursor: 'not-allowed' }}
                          onClick={
                            uploadable
                              ? () => {
                                  setCurrentIndex(3);
                                  setSelectedRound('3');
                                }
                              : () => {}
                          }
                        >
                          <i className="i-upload"></i> นำเข้าข้อมูล
                        </a>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </>
          );
        })}

      {['1', '2', '4'].map((roundNumber) => {
        if (adminConfig.current_round !== roundNumber) {
          return <></>;
        }

        const renderRound = (subround) => {
          const roundname = subround ? `${roundNumber}${subround}` : `${roundNumber}`;
          const round = find(ROUNDS, { value: `${roundname}_2568` });
          const isDisplayDownloadStudents = adminConfig[`isopen_for_round_${roundname}_enrollment_offer_students`];
          return (
            <>
              <h2>รอบ {round.label}</h2>

              <ul className="sec-import -enrollments">
                {Array(2)
                  .fill()
                  .map((_, index) => {
                    const {
                      title,
                      exampleFilePath,
                      filePath,
                      updatedAt,
                      uploadable,
                      count,
                      passCount,
                      failCount,
                      studentInfosFilePath,
                    } = useImportInfo(roundname, university, index + 1);

                    let displayImport;
                    if (index === 0) {
                      displayImport = adminConfig[`isopen_for_round_${roundname}_enrollment_offer`];
                    } else {
                      displayImport = adminConfig[`isopen_for_round_${roundname}_enrollment_result`];
                    }

                    const displayPassFailCount = passCount > 0 || failCount > 0;
                    return (
                      <li key={index} className={`round-${index + 1}`}>
                        <h2>
                          <b className="num">{index + 1}</b>
                          <b>
                            {title}
                            {index === 0 && <i>{count ? `(${numeral(count).format('0,0')} รายการ)` : ''}</i>}
                            {index === 1 && displayPassFailCount && (
                              <i>
                                {`ผ่าน ${numeral(passCount).format('0,0')} รายการ`}
                                <br />
                                {`ไม่ผ่าน ${numeral(failCount).format('0,0')} รายการ`}
                                <br />
                                {`รวม ${numeral(count).format('0,0')} รายการ`}
                              </i>
                            )}
                          </b>
                        </h2>
                        <a className="example" href={exampleFilePath}>
                          ดาวน์โหลดไฟล์ตัวอย่าง
                        </a>
                        {filePath ? (
                          <div className="file">
                            <a href={filePath}>
                              <i className="i-course"></i>
                              <b>{filePath.split('/').pop()}</b>
                              <small>{moment(updatedAt).add(543, 'years').format('D MMM YY HH:mm น.')} </small>
                            </a>
                            {studentInfosFilePath && isDisplayDownloadStudents && (
                              <a className="file-students" href={studentInfosFilePath}>
                                <i className="i-course"></i>
                                <b>{studentInfosFilePath.split('/').pop()}</b>
                                <small>{moment(updatedAt).add(543, 'years').format('D MMM YY HH:mm น.')} </small>
                              </a>
                            )}
                          </div>
                        ) : (
                          <div className="file -none">
                            <a href="#">
                              <i className="i-course"></i>
                              <b>ยังไม่มีข้อมูล</b>
                            </a>
                          </div>
                        )}

                        {displayImport ? (
                          <div className="action">
                            <a
                              className={cn('btn-main', { 'btn-disabled': !uploadable })}
                              style={uploadable ? null : { cursor: 'not-allowed' }}
                              onClick={
                                uploadable
                                  ? () => {
                                      setCurrentIndex(index + 1);
                                      setSelectedRound(roundname);
                                    }
                                  : () => {}
                              }
                            >
                              <i className="i-upload"></i> นำเข้าข้อมูล
                            </a>
                          </div>
                        ) : (
                          <div className="action">
                            <a>ปิดการนำเข้าข้อมูล</a>
                          </div>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </>
          );
        };

        if (roundNumber === '4') {
          return [1, 2].map((subround) => renderRound(subround));
        } else {
          return renderRound();
        }
      })}

      <ImportModal
        index={currentIndex}
        round={selectedRound}
        onCancel={() => setCurrentIndex()}
        university={university}
        config={{
          onUploadDone: async (results) => {
            const _university = await fetchUniversityInfo();
            if (currentIndex === 1) {
              // download(get(_university, `round_${adminConfig.current_round}.latest_students_file`));
            }

            if (results.errors) {
              const errors = results.errors.reduce((rows, { index, messages }) => {
                return rows.concat(messages.map((message) => ({ index, message })));
              }, []);
              setErrorTitle('นำข้อมูลเข้าระบบสำเร็จ แต่พบนักเรียนที่ยังไม่ลงทะเบียนในระบบ');

              console.log(errors);
              setImportErrors(errors);
            }
          },
        }}
        onErrorsChange={(errors) => {
          setErrorTitle(
            { 1: 'ไม่สามารถอัปโหลดข้อมูลการรับสมัครได้', 2: 'ไม่สามารถอัปโหลดข้อมูลผลการรับสมัครได้' }[currentIndex]
          );
          setCurrentIndex();
          setImportErrors(errors);
        }}
      />

      <VideoModal isVisible={isVideoModalVisible} vimeoId={'642471244'} setIsVisible={setIsVideoModalVisible} />
    </div>
  );
};

export default ImportEnrollment;
