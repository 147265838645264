module.exports = [
  {
    value: '1_2568',
    label: 'Portfolio',
  },
  {
    value: '2_2568',
    label: 'Quota',
  },
  {
    value: '3_2568',
    label: 'Admission',
  },
  {
    value: '4_2568',
    label: 'Direct Admission',
  },
  {
    value: '41_2568',
    label: 'Direct Admission 1',
  },
  {
    value: '42_2568',
    label: 'Direct Admission 2',
  },
];
